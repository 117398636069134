.userInfo {
    width: 100%;
    font-size: 16px;
}

.userInfo_box {
    width: 90%;
    background-color: #fff;
    box-shadow: 0px 3px 10px #dadada;
    border-radius: 15px;
    padding: 10px;
    margin: 3% 5%;
    font-size: 15px;
}

.info_from {
    display: flex;
    flex-direction: column;
    margin: 5px 0;
}

.userInfo input {
    border: 1px solid rgba(217, 217, 217, 1);
    width: 100%;
    height: 31px;
    border-radius: 5px;
    background-color: rgba(242, 242, 242, 1);
    margin: 8px 0;
    padding: 0 5px;
}

.info_from_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
}

.info_from_title>p:first-child {
    font-weight: bold;
}

.info_from_title>p:last-child {
    color: rgb(0, 113, 220);
}

.userInfo_window {
    width: 100%;
    padding: 5px 0;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.userInfo_window>p:first-child {
    margin: 0 0 8px 0;
    font-weight: bold;
}

.newPassword {
    display: flex;
    align-items: center;
    justify-content: center;
}

.newPassword label {
    width: 100px;
    font-size: 14px;
}

.pop_button {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.pop_button>p:first-child {
    width: 40%;
    height: 40px;
    background-color: #0071DC;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.pop_button>p:last-child {
    width: 40%;
    height: 40px;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}