body {
  max-width: 490PX;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
  background: #f5f5f5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  display: flex;
  align-items: center;
  justify-content: center;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: 0;
  color: inherit;
  font-size: inherit;
}

li,
ol,
ul {
  list-style: none;
}

:root {
  --themeColor: rgba(211, 52, 65, 1);
  --themTextColor: rgba(7, 29, 42, 1);
  --themBorderRadius: 8px
}

/* 公共样式 */
.header {
  width: 100%;
  min-height: 50px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
}

.header img {
  width: 100%;
  height: 100%;
}

.backIcon {
  position: absolute;
  left: 10px;
  font-size: 20px;
}

/* antd组件修改 */
.adm-tab-bar-item-active {
  color: rgb(0, 113, 220) !important;
}

.adm-checkbox .adm-checkbox-icon {
  border-radius: 5px !important;
}

.adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
  border-color: rgb(0, 113, 220) !important;
  background-color: rgb(0, 113, 220) !important;
}

.adm-collapse-panel-content {
  color: #50565E !important;
}

.adm-mask {
  max-width: 490PX;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
}

.adm-popup-body {
  max-width: 490PX;
}

.adm-popup-body-position-bottom {
  left: auto !important;
}