.user {
    width: 100%;
    font-size: 16px;
}

.user_top {
    width: 100%;
    height: 197px;
    background: url('../../public/assets/index/bb1.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
}

.user_info {
    height: 50px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 20%;
    left: 5%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
}

.user_info>img:first-child {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.info_text {
    width: 58%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-left: 3%;
}

.info_text>p:first-child {
    font-size: 20px;
}

.user_id {
    width: auto;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 14px;
}

.user_id img {
    width: 18px;
    height: 18px;
    margin: 0 3px 3px;
}

.user_box {
    width: 90%;
    margin: -80px auto;
    position: relative;
}

.user_fun {
    width: 100%;
    height: 130px;
    background-color: #fff;
    color: #000;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 4px 6px 0px rgba(42, 79, 174, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.money_num {
    width: auto;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
}

.user_usdts {
    display: flex;
    align-items: center;
    color: #000;
}

.money_num img {
    width: 25px;
    height: 25px;
}

.user_usdts>p:nth-child(2) {
    font-weight: bold;
    font-size: 19px;
    margin: 4px 5px 0;
}

.user_usdts>p:nth-child(3) {
    margin: 5px 0 0;
}

.user_but {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.but_click {
    width: 45%;
    height: 41px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.but1 {
    background-color: #0071DC;
    color: #fff;
}

.but2 {
    background-color: #FFC220;
    color: #000;
}

.user_invite {
    width: 100%;
    background: url('../../public/assets/index/userSMALLBACK.svg') no-repeat;
    background-size: cover;
    border-radius: 10px;
    margin: 3% 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
}

.invite_text {
    width: 65%;
    font-size: 12px;
    color: rgb(102, 102, 102);
}

.user_invite>p:last-child {
    width: 30%;
    height: 36px;
    background-color: #FFC220;
    border-radius: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #000;
    text-align: center;
}

.user_list {
    height: 260px;
    margin: 3% 0;
    background-color: #fff;
    box-shadow: 0px 3px 10px #dadada;
    border-radius: 15px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.user_list_item {
    width: 100%;
    height: 41px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.list_item_name {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.list_item_name img {
    width: 22px;
    height: 22px;
    margin: 2px 0 0;
}

.list_item_name p {
    margin: 0 8px;
}

.Logout {
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #E6E6E6;
}

.outLogin {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
}

.outLogin>p:first-child {
    padding: 15px 0 25px;
}

.outLogin_fun {
    width: 100%;
    padding: 10px 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 0.5px solid #eee;
    font-size: 18px;
}

.outLogin_fun>p {
    width: 45%;
    text-align: center;
    color: #1677ff;
}

.outLogin_fun>p:last-child {
    color: red;
}