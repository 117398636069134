.Trade {
    width: 100%;
    font-size: 16px;
}

.Trade_box {
    padding: 10px;
}

.Trade_top {
    width: 100%;
    height: 104px;
    background-color: #0071DC;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding: 10px;
    font-size: 13px;
    border-radius: 10px;
    color: #fff;
}

.Trade_top>div {
    display: flex;
    align-items: center;
}

.Trade_top>div>p:last-child {
    font-size: 24px;
    font-weight: bold;
    margin: 0 5px 5px;
}

.Trade_title {
    width: 100%;
    height: auto;
    box-shadow: 0px 4px 6px 0px rgba(42, 79, 174, 0.15);
    padding: 10px;
    margin: 3% 0;
    border-radius: 10px;
}

.Trade_text {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    border-bottom: 1px solid rgba(35, 47, 62, 1);
}

.Trade_text>p:last-child {
    font-size: 12px;
    margin: 3% 0;
}

.Trade_start {
    width: 70%;
    height: 40px;
    margin-left: 15%;
    background-color: #0071DC;
    color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Trade_fun {
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Trade_fun>div {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Trade_fun>div:first-child {
    border-right: 1px solid rgba(35, 47, 62, 1);
}

.Trade_fun>div>p:last-child {
    color: rgba(35, 47, 62, 1);
    font-size: 19px;
    font-weight: bold;
}

.Trade_result {
    width: 100%;
    margin: 5% 0;
    font-size: 15px;
}

.Trade_result>div {
    display: flex;
    align-items: center;
}

.Trade_result>div>p:last-child {
    margin-left: 2%;
}

.Trade_result img {
    width: 30px;
    height: 30px;
}

.modelWindow {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 490PX;
    height: 100vh;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
}

.window_center {
    width: 90%;
    padding: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 8px;
    z-index: 3;
}

.pop_but_trade {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0071DC;
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    margin: 10px 0;
}

.cancel {
    background-color: #eee;
    color: #000;
}

.flex_bug {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex_bug p:last-child {
    margin: 0 0 0 3px;
}