.detail_box {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-size: cover;
    background-position: center;
    padding-top: 5px;
    font-size: 16px;
}

.detail_list {
    width: 90%;
    height: 156px;
    margin: 5px auto;
    background: url('/public/assets/index/newBackzijin.png') no-repeat;
    background-position: center;
    background-size: 100% 100%;
    border-radius: 20px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.detail_list>div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.detail_list>p:nth-child(2) {
    font-size: 20px;
    font-weight: bold;
}

.detail_list>div:last-child p:last-child {
    margin: 2% 0;
    font-size: 12px;
}